<template>
  <div class="box">
    <!-- tabs -->
    <van-sticky>
      <van-tabs v-model="tabs"
        color="#ffc002"
        @change="onClickTab">
        <van-tab title="全部"></van-tab>
        <van-tab title="已报名"></van-tab>
        <van-tab title="已完成"></van-tab>
        <van-tab title="已拒绝"></van-tab>
      </van-tabs>
    </van-sticky>
    <!-- 列表 -->
    <div class="lists">
      <div class="listBox"
        v-for="item in list"
        :key="item.OrderId">
        <div class="list"
          @click="detailClick(item.OrderId)">
          <!-- <img :src="item.GoodsImgUrl" /> -->
          <div class="listRight"
            style="margin:5px;">
            <!-- <div>{{ item.Title }}
            </div> -->
            <div style="display: flex;justify-content: space-between;">
              <span>{{item.Title}}</span>
              <span v-if="item.Status == 1"
                class="icon"
                style="font-size: 12px;font-weight: 400;background: #6493e3;color: #fff;position: initial;">{{item.StatusDesc}}</span>
              <span class="icon"
                v-if="item.Status == 2"
                style="font-size: 12px;font-weight: 400;background: #47e39c;color: #fff;position: initial;">{{item.StatusDesc}}</span>
              <span class="icon"
                v-if="item.Status == 3"
                style="font-size: 12px;font-weight: 400;background: #e35258;color: #fff;position: initial;">{{item.StatusDesc}}</span>
            </div>
            <div class="titleC">
              <img src="../../assets/shop/shopd.png"
                v-if="item.Mobile">
              <span style="font-size:16px;color:#F09B0B">{{ item.Name }} {{ item.Mobile }}</span>

              <!-- <img src="../../assets/shop/shopb.png"
                v-if="item.EnrolTime">
              <span>{{ item.EnrolTime }}</span> -->
            </div>
            <div class="titleC">
              <img src="../../assets/shop/shopb.png"
                v-if="item.EnrolTime">
              <span>{{ item.EnrolTime }}</span>
            </div>
            <div class="titleC"
              v-if="item.Status == 3">
              <img v-if="item.Refreason != null"
                src="../../assets/shop/shopc.png">
              <span v-if="item.Refreason != null">{{ item.Refreason }}</span>
            </div>
          </div>
        </div>
        <div class="buttons">
          <div style="background:#1a89fa;color:#fff"
            @click="pjtcShow(item)"
            v-if="item.Status == 4">评价</div>
          <div style="background:#ff976a;color:#fff"
            @click="cdtjShow(item)"
            v-if="item.Status == 1 ">拒绝</div>
          <div style="background:#47e39c;color:#fff"
            @click="detele(item)"
            v-if="item.Status == 1">完成</div>
        </div>
      </div>
      <!-- 无数据 -->
      <van-empty :image-size="[30, 20]"
        description="暂无订单信息~"
        v-if="!list.length" />
    </div>
    <!-- tabbar栏 -->
    <van-tabbar v-model="tabbaron"
      active-color="#3a95cc"
      inactive-color="#ccc">
      <van-tabbar-item replace
        to="/shop"
        icon="shop-o">商城首页</van-tabbar-item>
      <van-tabbar-item replace
        to="/PromotionBm"
        icon="user-o">报名信息</van-tabbar-item>
    </van-tabbar>
    <!-- 弹出框 -->
    <van-popup v-model="cdshow"
      round
      position="bottom"
      :style="{ height: '20%' }">
      <van-cell-group inset>
        <van-field v-model="cdForm.Refreason"
          rows="2"
          autosize
          label="拒接原因"
          type="textarea"
          maxlength="120"
          placeholder="请输入拒接原因"
          show-word-limit />
      </van-cell-group>
      <button class="buttonClass"
        @click="cancellations()">提 交</button>
    </van-popup>
    <van-popup v-model="pjshow"
      round
      position="bottom"
      :style="{ height: '50%' }">
      <div style="text-align: center;font-size: 16px;font-weight: 700;margin: 15px 0px;">发布评价</div>

      <van-cell-group inset>
        <van-field v-model="evaluation.EvalContent"
          rows="1"
          autosize
          label="评价内容"
          type="textarea"
          placeholder="请输入评价内容" />
      </van-cell-group>
      <div style="margin: 10px auto;width: 88%;font-size: 14px;border-bottom: 1px solid #ebedf0;
    padding-bottom: 10px;">
        <span style="margin: 35px;">评分: </span>
        <van-rate v-model="evaluation.Satisfaction"
          :size="20"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee" />
      </div>
      <div style="margin: 10px auto;width: 85%;font-size: 14px;">
        <van-radio-group v-model="evaluation.OMNick"
          direction="horizontal">
          <van-radio name="1">匿名</van-radio>
          <van-radio name="0">不匿名</van-radio>
        </van-radio-group>
      </div>
      <div style="width: 85%;margin: 5px auto;">
        <van-uploader v-model="fileList"
          multiple
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :deletable="true"
          @delete="shanchu"
          :max-size="3500 * 1024"
          @oversize="onOversize" />
      </div>
      <!-- <van-uploader :after-read="afterRead" /> -->
      <button class="buttonClass"
        @click="evaluationClick()">提 交</button>
    </van-popup>
  </div>
</template>
<script>
import { ref } from 'vue';
import { WxGetActEnrolPage, WxRefuseMyTransaction, WxFinishMyTransaction, WeEvaluateOrder } from "@/api/shop";
import { getOpenId } from "@/utils/auth";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from 'vant';
Vue.use(Toast);
Vue.use(Dialog);
import config from "@/config";
import axios from "axios";
export default {
  setup () {
    const tabbaron = ref(1);
    return { tabbaron };
  },
  data () {
    return {
      tabs: 0, //tabs栏
      formList: {
        openID: "oebDw5XNiVzsIUaP__HJXNUd6krs",
        promotionId: 0,
      },
      list: [],
      tabbaron: 1,
      cdshow: false,
      pjshow: false,
      cdForm: {}, //撤单
      evaluation: { //评价
        OMNick: "0",
        Satisfaction: 5,
        EvalContent: "",
        EvalImgs: "",
      },
      uploadImgs: [],
      fileList: [],
    }
  },
  mounted () {
    this.getMyOrderPage(0);
  },
  methods: {
    // 图片上传
    afterRead (file) {
      file.status = "uploading";
      file.message = "上传中...";
      if (file.file.size <= 3500000) {
        let c = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        formData.append("file", file.file);
        // 添加请求头
        axios.post(config.apiUploadUrl, formData, c).then((res) => {
          if (res.data.code === 0) {
            var arr = this.uploadImgs;
            arr.push(res.data.data);
            this.uploadImgs = arr;
          }
          this.evaluation.EvalImgs = res.data.picurl;
          file.status = "";
          file.message = "";
        });
      } else {
        file.status = "failed";
        file.message = "上传失败";
        Toast("文件大小不能超过 4M");
      }
    },
    // 图片删除
    shanchu: function () {
      this.evaluation.EvalImgs = "";
    },
    // 图片大小限制提示
    onOversize () {
      Toast("文件大小不能超过 4M");
    },
    pjtcShow (row) {
      this.pjshow = true;
      this.evaluation.OpenID = "oebDw5XNiVzsIUaP__HJXNUd6krs";
      this.evaluation.OrderId = row.OrderId;
    },
    // 跳转详情
    detailClick (row) {
      var url = '/shop/orderDetail?Id=' + row
      this.$router.push(url);
    },
    // tabs点击
    onClickTab (row) {
      this.list = [],
        this.getMyOrderPage(row);
    },
    // 获取订单列表
    getMyOrderPage (row) {
      this.formList.status = row;
      WxGetActEnrolPage(this.formList).then((res) => {
        this.list = res.data.data;
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].OrderTime = this.dataFormat(this.list[i].OrderTime)
        }
      })
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
    //撤单
    cdtjShow (row) {
      this.cdForm.EnrolId = row.EnrolId;
      this.cdshow = true;
    },
    // 提交撤单
    cancellations () {
      this.cdForm.OpenID = getOpenId();
      // this.cdForm.OpenID = "oebDw5XNiVzsIUaP__HJXNUd6krs";
      WxRefuseMyTransaction(this.cdForm).then((res) => {
        if (res.data.code == 0) {
          this.cdshow = false;
          Toast.success('操作成功!');
          this.getMyOrderPage();
          this.cdForm.Refreason = "";
        } else {
          Toast.fail(res.data.msg);
          this.cdshow = false;
        }
      })
    },
    // 删除订单
    detele (row) {
      Dialog.confirm({
        title: '提醒',
        message:
          '确定要完成该活动交易?',
      })
        .then(() => {
          var openid = getOpenId();
          // var openid = "oebDw5XNiVzsIUaP__HJXNUd6krs";
          WxFinishMyTransaction({ OpenID: openid, EnrolId: row.EnrolId }).then((res) => {
            if (res.data.code == 0) {
              Toast.success('操作成功!');
              this.getMyOrderPage();
            } else {
              Toast.fail(res.data.msg);
            }
          })
        })
        .catch(() => {
          return false;
        });
    },
    // 评价
    evaluationClick () {
      WeEvaluateOrder(this.evaluation).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success('评价成功!');
          this.cdshow = false;
          this.evaluation = {
            OMNick: "0",
            Satisfaction: 5,
            EvalContent: "",
            EvalImgs: "",
          }
          this.getMyOrderPage();
        } else {
          Toast.fail(res.data.msg);
        }
      })
    },
  }
}
</script>
<style scoped>
.box {
  background: #f5f5f5;
  min-height: 100vh;
}

.lists {
  display: inline-block;
  float: left;
  width: 95%;
  margin: 0px 2.5%;
  overflow-y: scroll;
  padding-bottom: 60px;
}

.lists .listBox {
  margin: 10px 0px;
  background: #fff;
  border-radius: 8px;
}

.lists .list {
  display: flex;
  position: relative;
}

.lists .list img {
  width: 85px;
  height: 85px;
  border-radius: 25px;
  padding: 8px;
}

.lists .list .listRight {
  width: 100%;
}

.list .listRight div:nth-child(1) {
  /* float: left; */
  font-size: 14px;
  font-weight: 700;
  margin: 5px 5px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  position: relative;
}

.list .listRight div:nth-child(2) {
  /* margin: 0px 5px;
    position: absolute;
    bottom: 3px;
    width: 55%; */
}

.list .listRight .addcar {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #ffca36;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  position: absolute;
  right: 0px;
}

.shopbox {
  width: 95%;
  margin: 10px 2.5%;
  padding-bottom: 15px;
}

.shopbox .goodsList img {
  width: 95px;
  height: 70px;
  border-radius: 8px;
}

.shopbox .goodsList div:nth-child(3) {
  color: #ff4a26;
  margin-top: 3px;
}

.shopbox .goodsList div:nth-child(2) {
  font-size: 12px;
  width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/deep/.shopbox .van-swipe__indicators {
  display: none;
}

.list .listRight .titleC {
  /* float: left; */
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 3px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}

.list .listRight .titleC span {
}

.list .listRight .titleC img {
  width: 15px;
  height: 15px;
  padding: 0px 5px;
  margin: 0px;
}

.list .Amount {
  position: absolute;
  right: 5px;
  color: #e36b6b;
}

.list .icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 8px;
}

.buttonClass {
  width: 80%;
  margin: 10px 10%;
  background: #4b9fe3;
  color: #fff;
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  border-radius: 8px;
}

.listBox .buttons {
  display: flex;
  padding-bottom: 8px;
  padding-right: 20px;
  justify-content: flex-end;
}

.listBox .buttons div {
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 14px;
  margin-left: 5px;
}
</style>